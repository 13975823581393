import React, { useEffect, useState } from 'react';
import { useEditor, EditorContent } from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';
import Underline from '@tiptap/extension-underline';
import TextAlign from '@tiptap/extension-text-align'
import Link from '@tiptap/extension-link';
import OrderedList from '@tiptap/extension-ordered-list'
import BulletList from '@tiptap/extension-bullet-list'
import ListItem from '@tiptap/extension-list-item'
import { Form } from 'semantic-ui-react';
import { getTextFromHtml } from '../../../helpers/getTextFromHtml'
import { Label } from './sharedComponents';
import { FormInlineError } from './FormInlineError';
import EditorStyles from './EditorStyles';
import ToolbarButton from './ToolbarButton';
import LinkModal from './LinkModal';

const Wysiwyg = ({ name, validation = {}, errorMessage, label, register, unregister, setValue, errors, getDefault, description, asText, plainText }) => {
  const defaultValue = getDefault(name) || '';
  const [isModalOpen, setModalOpen] = useState(false);
  const [linkData, setLinkData] = useState({ text: "", url: "", newTab: false });
  
  const editor = useEditor({
    extensions: [StarterKit, 
        Underline, BulletList, ListItem, OrderedList,
        TextAlign.configure({
            types: ['heading', 'paragraph'],
        }),
        Link.extend({
            addAttributes() {
                return {
                    ...this.parent(),
                    target: {
                        default: null,
                        parseHTML: (element) => element.getAttribute("target"),
                        renderHTML: (attributes) => {
                            return attributes.target ? { target: attributes.target } : {};
                        },
                    },
                };
            },
        }).configure({
        openOnClick: true,
        autolink: true,
        HTMLAttributes: {
          rel: null,
          target: null,
        }
    })],
    content: defaultValue,
    onUpdate: ({ editor }) => {
    const markup = editor.getHTML()
    const markupWithoutParagraph = markup.slice(3, -5)
    const getText = getTextFromHtml(markupWithoutParagraph)
    const newValue = plainText ? getText : markupWithoutParagraph
    setValue(name, newValue)
    },
  });

  useEffect(() => {
    register({name}, {
      validate: {
        maxLength: value => (!validation.maxLength || value.length <= validation.maxLength),
        minLength: value => (!validation.minLength || value.length >= validation.minLength),
        required: value => (!validation.required || Boolean(value.trim())),
      },
    });
    setValue(name, defaultValue);
    return () => unregister(name);
  }, []);

  const handleOpenModal = () => {
      if (!editor) return
      const { state } = editor;
      const selectedText = state.doc.textBetween(state.selection.from, state.selection.to, " ");
      const existingUrl = editor.getAttributes("link").href || "";
      const existingTarget = editor.getAttributes("link").target === "_blank";
      setLinkData({ text: selectedText, url: existingUrl, newTab: existingTarget });
      setModalOpen(true);
    };

    const handleSave = (text, url, newTab) => {
      if (url && editor) {
        editor.commands.setLink({ href: url, target: newTab ? "_blank" : null });
        editor.commands.insertContent(text);
      }
      setModalOpen(false);
    };

    const formatOptions = [
      { command: () => editor.chain().focus().toggleBold().run(), active: editor && editor.isActive('bold'), icon: "bold" },
      { command: () => editor.chain().focus().toggleItalic().run(), active: editor && editor.isActive('italic'), icon: "italic" },
      { command: () => editor.chain().focus().toggleUnderline().run(), active: editor && editor.isActive('underline'), icon: "underline" },
      { command: () => editor.chain().focus().toggleStrike().run(), active: editor && editor.isActive('strike'), icon: "strikethrough" },
      { command: () => editor.chain().focus().toggleBulletList().run(), active: editor && editor.isActive('bulletList'), icon: "list ul" },
      { command: () => editor.chain().focus().toggleOrderedList().run(), active: editor && editor.isActive('orderedList'), icon: "list ol" },
      { command: handleOpenModal, active: editor && editor.isActive('link'), icon: "linkify" },
      { command: () => editor.chain().focus().unsetLink().run(), active: editor && editor.isActive('link'), icon: "unlinkify", disabled: editor && !editor.isActive('link') },
    ];

  return (
    <Form.Field error={!!errors[name]}>
      <Label required={validation.required} text={label} description={description} />
      {!asText && editor && (
        <EditorStyles>
            <div className="menu-bar">
                <select onChange={(e) => {
                    const level = Number(e.target.value);
                    if (level) {
                        editor.chain().focus().toggleHeading({ level }).run();
                    } else {
                        editor.chain().focus().setParagraph().run();
                    }
                }}>
                <option value="">Paragraph</option>
                {[1, 2, 3, 4, 5, 6].map(level => <option key={level} value={level}>H{level}</option>)}
                </select>
                {formatOptions.map(({ command, active, icon, disabled }) => (
                <ToolbarButton disabled={disabled} key={icon.name} onClick={command} active={active} icon={icon} />
                ))}
            </div>
          <EditorContent editor={editor} className="tiptap-editor" />
        </EditorStyles>
      )}
      {asText && <span dangerouslySetInnerHTML={{ __html: defaultValue }} />}
      {!asText && errors[name] && <FormInlineError>{errorMessage}</FormInlineError>}
      <LinkModal
        isOpen={isModalOpen}
        onClose={() => setModalOpen(false)}
        onSave={handleSave}
        defaultText={linkData.text}
        defaultUrl={linkData.url}
        defaultNewTab={linkData.newTab}
      />
    </Form.Field>
  );
};

export default Wysiwyg;
