import styled from 'styled-components';

const EditorStyles = styled.div`
  background: #fff;
  border: 1px solid rgba(34, 36, 38, 0.15);
  border-radius: 0.28571429rem;
  padding: 15px;
  font-size: 14px;
  min-height: 100px;

  .menu-bar {
    display: flex;
    gap: 8px;
    padding-bottom: 8px;
  }

  .menu-bar button {
    border: none;
    background: none;
    padding: 5px 8px;
    cursor: pointer;
    font-size: 14px;
    transition: 0.2s;
  }

  .menu-bar button.active {
    background: #007bff;
    color: white;
    border-radius: 3px;
  }

  .ProseMirror {
    min-height: 200px;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 16px;
    line-height: 1.5;
    outline: none;
  }

  .ProseMirror:focus {
    border-color: #007bff;
  }

  /* Styling for text formatting */
  .ProseMirror h1 {
    font-size: 24px;
    font-weight: bold;
  }

  .ProseMirror h2 {
    font-size: 20px;
    font-weight: bold;
  }

  .ProseMirror h3 {
    font-size: 18px;
    font-weight: bold;
  }

  .ProseMirror blockquote {
    border-left: 4px solid #ccc;
    padding-left: 10px;
    color: #666;
    font-style: italic;
  }

  .ProseMirror ul {
    padding-left: 20px;
    list-style-type: disc;
  }

  .ProseMirror ol {
    padding-left: 20px;
    list-style-type: decimal;
  }

  .ProseMirror strong {
    font-weight: bold;
  }

  .ProseMirror em {
    font-style: italic;
  }

  .ProseMirror u {
    text-decoration: underline;
  }

  .ProseMirror a {
    color: #007bff;
    text-decoration: underline;
    cursor: pointer;
  }

  .ProseMirror a:hover {
    color: #0056b3;
  }
`;

export default EditorStyles;
