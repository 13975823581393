import React from 'react';
import { Icon } from 'semantic-ui-react';

const ToolbarButton = ({ onClick, active, icon, disabled }) => (
  <button onClick={onClick} className={active ? 'active' : ''} disabled={disabled}>
    <Icon name={icon} size={18} />
  </button>
);

export default ToolbarButton;
