import React, { useState, useEffect } from "react";
import styled from "styled-components";

const Overlay = styled.div`
  position: fixed;
  inset: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.5);
  z-index: 2;
`;

const ModalContainer = styled.div`
  background: white;
  padding: 16px;
  border-radius: 8px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  width: 300px;
`;

const FormContainer = styled.div`
    margin-bottom: 8px;
`;

const Label = styled.label`
  font-size: 14px;
  font-weight: bold;
  color: #333;
  margin-bottom: 5px;
`;

const CheckboxContainer = styled.label`
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 14px;
  margin-bottom: 12px;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 8px;
`;

const Button = styled.button`
  padding: 8px 12px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  background: ${({ primary }) => (primary ? "#007BFF" : "#ccc")};
  color: ${({ primary }) => (primary ? "white" : "black")};

  &:hover {
    background: ${({ primary }) => (primary ? "#0056b3" : "#b3b3b3")};
  }
`;

const LinkModal = ({
  isOpen,
  onClose,
  onSave,
  defaultText,
  defaultUrl,
  defaultNewTab,
}) => {
  const [text, setText] = useState(defaultText || '');
  const [url, setUrl] = useState(defaultUrl || '');
  const [newTab, setNewTab] = useState(defaultNewTab || false);

  useEffect(() => {
    if (isOpen) {
      setText(defaultText || '');
      setUrl(defaultUrl || '');
      setNewTab(defaultNewTab || false);
    }
  }, [isOpen, defaultText, defaultUrl, defaultNewTab]);

  if (!isOpen) return null;

  return (
    <Overlay>
      <ModalContainer>
        <FormContainer>
            <Label htmlFor="text-input">Link Title</Label>
            <input id="text-input" placeholder="Link Text" value={text} onChange={(e) => setText(e.target.value)} type="text" />
        </FormContainer>
        <FormContainer>
            <Label htmlFor="text-input">Link target</Label>
            <input id="url-input" placeholder="URL" value={url} onChange={(e) => setUrl(e.target.value)} type="text" />
        </FormContainer>
        <CheckboxContainer>
          <input
            type="checkbox"
            checked={newTab}
            onChange={(e) => setNewTab(e.target.checked)}
          />
          Open link in new tab
        </CheckboxContainer>
        <ButtonContainer>
          <Button onClick={onClose}>Cancel</Button>
          <Button primary onClick={() => onSave(text, url, newTab)}>Save</Button>
        </ButtonContainer>
      </ModalContainer>
    </Overlay>
  );
};

export default LinkModal;
