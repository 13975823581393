import React, { useEffect, useState } from 'react'
import { EditorContent, useEditor } from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';
import Underline from '@tiptap/extension-underline';
import { Form } from 'semantic-ui-react'
import styled from 'styled-components'
import { FormInlineError } from './FormInlineError'
import { Label } from './sharedComponents'
import { getTextFromHtml } from '../../../helpers/getTextFromHtml'
import EditorStyles from './EditorStyles';
import ToolbarButton from './ToolbarButton';

const CharsCount = styled.span`
  color: rgba(0, 0, 0, 0.7);
  position: absolute;
  right: 10px;
  font-size: 12px;
  font-weight: 500;
`

export default function Textarea(props) {
  const {
    name,
    validation = {},
    errorMessage,
    label,
    register,
    unregister,
    setValue,
    errors,
    asText,
    getDefault,
    description,
    plainText,
    componentIndex = 0,
    componentName = 'form',
    originName = name,
    initialValue = '',
  } = props

  const defaultValue = getDefault(name) || initialValue
  const [charsCount, setCharsCount] = useState('')

  const editor = useEditor({
    extensions: [StarterKit, Underline ],
    content: defaultValue,
    onUpdate: ({ editor }) => {
      const html = editor.getHTML();
      const text = getTextFromHtml(html);
      setValue(name, plainText ? text : html);
      setCharsCount(text.length > 0 ? `${text.length} character${text.length > 1 ? 's' : ''}` : '');
    },
  });

  useEffect(() => {
    register(
      { name },
      {
        validate: {
          maxLength: value => {
            if (!validation.maxLength || !value) return true;
            return getTextFromHtml(value.trim()).length <= validation.maxLength;
          },
          minLength: value => {
            if (!validation.minLength || !value) return true;
            return getTextFromHtml(value.trim()).length >= validation.minLength;
          },
          required: value => {
            if (!validation.required) return true;
            return Boolean(getTextFromHtml(value.trim()));
          },
        },
      }
    );
    setValue(name, defaultValue);
    setCharsCount('');
    return () => unregister(name);
  }, []);

  const formatOptions = [
    { command: () => editor.chain().focus().toggleBold().run(), active: editor && editor.isActive('bold'), icon: "bold" },
    { command: () => editor.chain().focus().toggleItalic().run(), active: editor && editor.isActive('italic'), icon: "italic" },
    { command: () => editor.chain().focus().toggleUnderline().run(), active: editor && editor.isActive('underline'), icon: "underline" },
  ]

  return (
    <Form.Field
      error={!!errors[name]}
      id={`${componentIndex}-${componentName}-${originName}-text-wrapper`}
      style={{ position: 'relative' }}
    >
      <Label required={validation.required} text={label} description={description}>
        <CharsCount>{charsCount}</CharsCount>
      </Label>
      {!asText && (
        <EditorStyles>
          {!plainText && (
            <div className="menu-bar">
            {formatOptions.map(({ command, active, icon }) => (
              <ToolbarButton key={icon.name} onClick={command} active={active} icon={icon} />
            ))}
          </div>  
          )}
          <EditorContent editor={editor} />
        </EditorStyles>
      )}
      {asText && <span dangerouslySetInnerHTML={{ __html: defaultValue }} />}
      {!asText && errors[name] && <FormInlineError>{errorMessage}</FormInlineError>}
    </Form.Field>
  )
}
