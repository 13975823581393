export const getDouglasPageLink = briefing => {
  const { country, identifier, type, language } = briefing
  const firstCountry = country.split(',')[0]
  if (country === 'EU_INTERNATIONAL' || country === 'EU') {
    return null
  }

  let baseUrl = ''
  if (firstCountry === 'fr') {
    baseUrl = 'https://www.nocibe.fr'
  } else {
    baseUrl = `https://www.douglas.${firstCountry.replace('_NEW', '')}/${language}`
  }
  switch (type) {
    case 'contentpages':
    case 'promos':
      return `${baseUrl}/cp/${identifier}/${identifier}`
    case 'pop':
    case 'pop_cp':
      return `${baseUrl}/c/${identifier}`
    case 'pdp':
      return `${baseUrl}/p/${identifier}`
    default:
      break
  }
}

export const getDouglasDomain = country => {
  const firstCountry = country.split(',')[0]
  if (firstCountry === 'fr') {
    return 'https://www.nocibe.fr'
  }

  return `https://www.douglas.${firstCountry.replace('_NEW', '')}`
}

export const getDouglasPreviewUrl = props => {
  let country = props.country.replace('_NEW', '').toLowerCase()
  let language = props.language || 'de'
  if (country === 'eu' || country === 'eu_international') {
    country = 'de'
    language = 'de'
  }

  const path = language + '/baaspreview/' + props.id + '?tracking=false'

  // as long as CZ and SK do not have a environment
  if (country === 'cz' || country === 'sk' || country === 'lt') {
    return 'https://www.douglas.de/de/baaspreview/' + props.id + '?tracking=false'
  }

  if (window.location.hostname === 'baas.dglecom.net') {
    return 'http://localhost:3100/' + path
  }

  if (country === 'fr') {
    return 'https://www.nocibe.fr/' + path
  }

  if (window.location.hostname === 'briefing-as-a-service.d1.pub.dglecom.net') {
    return 'https://' + country + '.shop.s1.pub.dglecom.net/' + path
    //return 'https://stg-storefront-' + country + '-s1.pub.az-we.dglecom.net/' + path
  }

  return 'https://www.douglas.' + country + '/' + path
  //return 'https://prd-smartedit-' + country + '-p1.pub.dglecom.net/' + path
}
